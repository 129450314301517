import { Controller } from '@hotwired/stimulus';
import type MuxPlayerElement from '@mux/mux-player';
import debug from 'debug';

import MediaTracks from '../mux/media_tracks';
import HlsHacks from '../mux/hls_hacks';
import persistentSettings from '../mux/persistent_settings';

const log = debug('player');

/**
 * A controller adding functionality to a <mux-player> web component.
 *
 * It's responsibilities are:
 * - setting the right audio/subtitles language depending on various variables
 * - preserving player settings (e.g. playback rate, mute state)
 * - fixing bugs in hls.js and mux web components to provide good UX
 * - providing an abstraction over the player that parent controllers can use
 */
export default class extends Controller<MuxPlayerElement> {
  static values = {
    defaultAudioLanguage: String,
    defaultSubtitlesLanguage: String,
    forceAudioLanguage: String,
    forceSubtitlesLanguage: String,
  };

  declare defaultAudioLanguageValue: string;
  declare defaultSubtitlesLanguageValue: string;
  declare forceAudioLanguageValue: string;
  declare forceSubtitlesLanguageValue: string;

  declare mediaTracks: MediaTracks;
  declare hlsHacks: HlsHacks | null;
  declare connected: boolean;

  connect() {
    this.mediaTracks = new MediaTracks(this.element);

    this.connected = true;
    // We need to give the web component time to (re-)initialize everything
    setTimeout(() => {
      if (!this.connected) return;
      this.setup();
    }, 0);
  }

  setup() {
    this.hlsHacks = HlsHacks.buildIfHlsJsInitialized(this.element);
    if (this.hlsHacks) {
      this.hlsHacks.pausePlaybackWhileSwitchingAudioTracks();
      this.hlsHacks.logErrors();
    }

    if (persistentSettings.muted != null) {
      this.element.muted = persistentSettings.muted;
    }
    if (persistentSettings.playbackRate != null) {
      this.element.playbackRate = persistentSettings.playbackRate;
    }

    if (this.hlsHacks) {
      // The browser doesn't support HLS natively, so hls.js is used, which means
      // we can hook into its events to configure audio/subtitles/renditions as soon
      // as possible.
      log('Using hls.js');

      this.hlsHacks.onAudioTracksUpdated(() => {
        log('hls.js: audio tracks updated');
        if (!this.hlsHacks) return; // Shouldn't happen

        log('mediaTracks langs: %o', this.mediaTracks.audioLanguages);
        const language = this.pickLanguage('audio', this.hlsHacks.audioLanguages, [
          this.forceAudioLanguageValue,
          persistentSettings.audioLanguage,
          this.defaultAudioLanguageValue,
          this.hlsHacks.defaultAudioLanguage,
          this.hlsHacks.firstAudioLanguage,
          this.mediaTracks.audioLanguages[0],
        ]);

        if (!language) throw new Error('no audio track');

        this.hlsHacks.audioLanguage = language;

        // Update the audio tracks in the DOM as well
        this.mediaTracks.activeAudioLanguage = language;
      });

      this.hlsHacks.onSubtitleTracksUpdated(() => {
        log('hls.js: subtitle tracks updated');

        const hidden = persistentSettings.subtitlesVisible === false;

        const language = hidden
          ? null
          : this.pickLanguage('subtitles', this.mediaTracks.subtitlesLanguages, [
              this.forceSubtitlesLanguageValue,
              persistentSettings.subtitlesLanguage,
              this.defaultSubtitlesLanguageValue,
              this.mediaTracks.activeSubtitlesLanguage,
              this.mediaTracks.subtitlesLanguages[0],
            ]);

        // For this to work in Chrome we need to wait until the next change event
        this.mediaTracks.onFirstSubtitlesChange(() => {
          this.mediaTracks.activeSubtitlesLanguage = language;
        });
      });

      // Save/restore selected quality (video rendition)
      // NOTE: Renditions API is non-standard and only supported by hls.js
      //       (Safari doesn't let users switch video quality manually)
      this.hlsHacks.onManifestParsed(() => {
        if (persistentSettings.quality != null) {
          this.mediaTracks.activeRendition = persistentSettings.quality;
        }
        this.mediaTracks.onRenditionChange(() => {
          persistentSettings.quality = this.mediaTracks.activeRendition;
        });
      });
    } else {
      // The browser does support HLS natively, so there is no hls.js to hook into and
      // we need to rely on DOM events only.
      log('Using native HLS');
    }

    this.element.addEventListener('loadedmetadata', this.onMetadataLoad, { once: true });

    this.element.addEventListener('volumechange', this.onVolumeChange);
    this.element.addEventListener('ratechange', this.onPlaybackRateChange);
  }

  onMetadataLoad = () => {
    if (!this.hlsHacks) {
      // On Safari audio/text tracks are not available at loadedmetadata time
      // and there's no better event that we could wait on to be sure that all
      // tracks are loaded.

      // Let's pick the right track when it gets added
      // NOTE: This event will get triggered once per track, so we might
      //       set the language to a wrong one at first and only fix it
      //       later when the correct one gets added. Luckily, at least on
      //       Safari, the 'change' events get triggered after all the
      //       tracks are already added (and events handled), so we don't
      //       have to worry about the temporarily-wrong tracks getting
      //       persisted.
      this.mediaTracks.onAudioAdd(() => {
        const language = this.pickLanguage('audio', this.mediaTracks.audioLanguages, [
          this.forceAudioLanguageValue,
          persistentSettings.audioLanguage,
          this.defaultAudioLanguageValue,
          this.mediaTracks.audioLanguages[0],
        ]);

        this.mediaTracks.activeAudioLanguage = language;
      });

      this.mediaTracks.onSubtitlesAdd(() => {
        if (!this.mediaTracks.subtitlesLanguages.length) return;
        if (persistentSettings.subtitlesVisible === false) return;

        const language = this.pickLanguage('subtitles', this.mediaTracks.subtitlesLanguages, [
          this.forceSubtitlesLanguageValue,
          persistentSettings.subtitlesLanguage,
          this.defaultSubtitlesLanguageValue,
          this.mediaTracks.activeSubtitlesLanguage,
          this.mediaTracks.subtitlesLanguages[0],
        ]);

        this.mediaTracks.activeSubtitlesLanguage = language;
      });
    }

    this.mediaTracks.onAudioChange(this.onAudioChange);
    this.mediaTracks.onSubtitlesChange(this.onSubtitlesChange);
  };

  /**
   *  Picks the first supported language from given list
   */
  pickLanguage(
    targetName: string,
    availableLanguages: string[],
    arrayOfPreference: (string | null | undefined)[]
  ): string | null {
    log('Picking %s language', targetName);
    log('Available: %o; Candidates: %o', availableLanguages, arrayOfPreference);

    const language =
      arrayOfPreference
        .filter(Boolean)
        .find((lang) => availableLanguages.includes(lang as string)) || null;
    log('Selected: %o', language);
    return language;
  }

  onAudioChange = () => {
    const language = this.mediaTracks.activeAudioLanguage;
    log('onAudioChange: %o', language);
    if (!language) return;
    persistentSettings.audioLanguage = language;
  };

  onSubtitlesChange = () => {
    const language = this.mediaTracks.activeSubtitlesLanguage;
    log('onSubtitlesChange: %o', language);
    persistentSettings.subtitlesVisible = language != null;
  };

  onVolumeChange = () => {
    // Volume is already preserved by Mux player internals, but mute state isn't
    persistentSettings.muted = this.element.muted;
  };

  onPlaybackRateChange = () => {
    if (this.element.playbackRate < 1) return;
    persistentSettings.playbackRate = this.element.playbackRate;
  };

  get position(): number {
    return this.element.currentTime;
  }

  set position(position: number) {
    this.element.currentTime = position;
  }

  play() {
    setTimeout(() => {
      try {
        void this.element.play();
      } catch (error) {
        console.log("Couldn't start playing");
      }
    }, 0);
  }

  disconnect() {
    this.connected = false;
    this.element.removeEventListener('volumechange', this.onVolumeChange);
    this.element.removeEventListener('ratechange', this.onPlaybackRateChange);
    this.element.removeEventListener('loadedmetadata', this.onMetadataLoad);
    this.mediaTracks.stopListening();
    // NOTE: all HLS listeners are automatically removed, so we don't need to do that
    if (!this.element.paused) this.element.pause();
    this.element.autoplay = false;
  }
}
