// This one is set by Mux Player (or Media Chrome to be specific)
const SUBTITLES_LANGUAGE = 'media-chrome-pref-subtitles-lang';

// These ones we set ourselves
const AUDIO_LANGUAGE = 'betrained-player-audio-language';
const SUBTITLES_VISIBLE = 'betrained-player-subtitles-visible';
const PLAYBACK_RATE = 'betrained-player-rate';
const MUTED = 'betrained-player-muted';
const QUALITY = 'betrained-player-quality';

export default {
  get audioLanguage(): string | null {
    return localStorage.getItem(AUDIO_LANGUAGE);
  },

  set audioLanguage(language: string) {
    localStorage.setItem(AUDIO_LANGUAGE, language);
  },

  get subtitlesVisible(): boolean | null {
    switch (localStorage.getItem(SUBTITLES_VISIBLE)) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  },

  set subtitlesVisible(visible: boolean) {
    localStorage.setItem(SUBTITLES_VISIBLE, String(visible));
  },

  get subtitlesLanguage(): string | null {
    return localStorage.getItem(SUBTITLES_LANGUAGE);
  },

  get playbackRate(): number | null {
    const value = localStorage.getItem(PLAYBACK_RATE);
    if (!value) return null;
    return parseFloat(value);
  },

  set playbackRate(rate: number) {
    localStorage.setItem(PLAYBACK_RATE, String(rate));
  },

  get muted(): boolean | null {
    switch (localStorage.getItem(MUTED)) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  },

  set muted(value: boolean) {
    localStorage.setItem(MUTED, String(value));
  },

  get quality(): number | null {
    const value = localStorage.getItem(QUALITY);
    if (!value) return null;
    return Number(value);
  },

  set quality(value: number | null) {
    if (value == null) {
      localStorage.removeItem(QUALITY);
    } else {
      localStorage.setItem(QUALITY, String(value));
    }
  },
};
