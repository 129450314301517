import MuxPlayerElement from '@mux/mux-player';
import Hls, { Events, HlsListeners, BufferFlushingData, ErrorData } from 'hls.js';

// Various hacks for Hls.js
class HlsHacks {
  declare readonly hls: Hls;
  declare readonly element: MuxPlayerElement;

  constructor(element: MuxPlayerElement) {
    if (!element._hls) throw new Error('HLS not initialized');
    this.hls = element._hls;
    this.element = element;
  }

  static buildIfHlsJsInitialized(element: MuxPlayerElement): HlsHacks | null {
    if (!element._hls) return null;
    return new HlsHacks(element);
  }

  // Stops playback while flushing buffers (e.g. after audio language change)
  // Based on: https://github.com/video-dev/hls.js/issues/5881#issuecomment-1750424721
  pausePlaybackWhileSwitchingAudioTracks() {
    let waitingForBufferFlush = false;
    let previousPlaybackRate = 1;

    this.hls.on(Events.BUFFER_FLUSHING, (_e: Events.BUFFER_FLUSHING, data: BufferFlushingData) => {
      // Only wait for flush if this is the main buffer
      if (
        data.startOffset <= this.element.currentTime &&
        data.endOffset >= this.element.currentTime
      ) {
        waitingForBufferFlush = true;
        // This causes the video to show a buffering state until flushing is finished
        previousPlaybackRate = this.element.playbackRate;
        this.element.playbackRate = 0;
      }
    });
    this.hls.on(Events.BUFFER_FLUSHED, () => {
      if (!waitingForBufferFlush) return;
      waitingForBufferFlush = false;
      this.element.playbackRate = previousPlaybackRate;
      // Seeking is required to flush the low-level playback buffers
      this.element.currentTime -= 0.001;
    });
  }

  logErrors() {
    this.hls.on(Events.ERROR, (_e: Events.ERROR, data: ErrorData) => {
      console.error(data);
    });
  }

  onAudioTracksUpdated(callback: HlsListeners[Events.AUDIO_TRACKS_UPDATED]) {
    this.hls.on(Events.AUDIO_TRACKS_UPDATED, callback);
  }

  onSubtitleTracksUpdated(callback: HlsListeners[Events.SUBTITLE_TRACKS_UPDATED]) {
    this.hls.on(Events.SUBTITLE_TRACKS_UPDATED, callback);
  }

  onManifestParsed(callback: HlsListeners[Events.MANIFEST_PARSED]) {
    this.hls.on(Events.MANIFEST_PARSED, callback);
  }

  get firstAudioLanguage(): string | null {
    return this.hls.audioTracks[0]?.lang || null;
  }

  get defaultAudioLanguage(): string | null {
    return this.hls.audioTracks.find((track) => track.default)?.lang || null;
  }

  get audioLanguages(): string[] {
    return this.hls.audioTracks.map((track) => track.lang as string);
  }

  set audioLanguage(language: string) {
    const trackId = this.hls.audioTracks.find((track) => track.lang === language)?.id;
    if (trackId == null) throw new Error(`No such audio track: ${language}`);
    this.hls.audioTrack = trackId;
  }
  // NOTE: all HLS listeners are automatically removed, so we don't need to do that
}

export default HlsHacks;
