import { Controller } from '@hotwired/stimulus';
import type MuxPlayerElement from '@mux/mux-player';
import { post, put } from '@rails/request.js';
import type PlayerController from './player_controller';

export default class extends Controller<HTMLElement> {
  static values = {
    position: Number,
    updatePositionPath: String,
    markShownPath: String,
  };
  static targets = ['completeForm'];
  static outlets = ['player'];

  declare positionValue: number;
  declare updatePositionPathValue: string;
  declare markShownPathValue: string;
  declare readonly playerOutlet: PlayerController;
  declare readonly playerOutletElement: MuxPlayerElement;
  declare readonly hasPlayerOutlet: boolean;
  declare readonly completeFormTarget: HTMLFormElement;

  declare intervalHandle: ReturnType<typeof setInterval>;
  declare ended: boolean;

  onVideoEnd = () => {
    this.completeFormTarget.requestSubmit();
    this.ended = true;
  };

  everyTwoSeconds = () => {
    if (this.ended) return;

    const currentPosition = Math.floor(this.playerOutlet.position);
    if (currentPosition === this.positionValue) return;

    this.positionValue = currentPosition;

    const data = new FormData();
    data.append('position', String(this.positionValue));
    void put(this.updatePositionPathValue, { body: data });
  };

  playerOutletConnected() {
    this.ended = false;
    this.playerOutlet.position = this.positionValue || 0;
    this.playerOutletElement.addEventListener('ended', this.onVideoEnd);
    this.intervalHandle = setInterval(this.everyTwoSeconds, 2000);
    this.playerOutlet.play();
    void post(this.markShownPathValue);
  }

  playerOutletDisconnected(_outlet: PlayerController, element: MuxPlayerElement) {
    clearInterval(this.intervalHandle);

    // Can't use this.playerOutletElement here as it either doesn't exist or points
    // to a wrong instance of the player. The correct one is already disconnected.
    element.removeEventListener('ended', this.onVideoEnd);
  }
}
