import type MuxPlayerElement from '@mux/mux-player';

/**
 * Provides easy to use accessors for the audio and subtitles tracks stored in the DOM
 * (or DOM-compatible API provided by polyfills).
 *
 * It also allows access to video renditions (different versions of varying quality/size),
 * which is technically not part of any spec, but is used by Mux player components
 *
 * See:
 * - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/audioTracks
 * - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/textTracks
 * - https://www.npmjs.com/package/media-tracks
 */
export default class MediaTracks {
  declare readonly element: MuxPlayerElement;
  declare cleanup: (() => void)[];

  constructor(element: MuxPlayerElement) {
    this.element = element;
    this.cleanup = [];
  }

  get subtitlesTracks() {
    if (!this.element.textTracks) throw new Error('textTracks not available');

    return Array.from(this.element.textTracks).filter(
      (track) => track.kind === 'subtitles' || track.kind === 'captions'
    );
  }

  get subtitlesLanguages(): string[] {
    return this.subtitlesTracks.map((track) => track.language);
  }

  get activeSubtitlesLanguage(): string | null {
    return this.subtitlesTracks.find((track) => track.mode === 'showing')?.language || null;
  }

  set activeSubtitlesLanguage(language: string | null) {
    this.subtitlesTracks
      .filter((track) => track.language !== language)
      .forEach((track) => {
        track.mode = 'disabled';
      });
    // Chrome doesn't like doing everything in 1 loop
    this.subtitlesTracks
      .filter((track) => track.language === language)
      .forEach((track) => {
        track.mode = 'showing';
      });
  }

  get audioTracks() {
    if (!this.element.audioTracks) throw new Error('audioTracks not available');

    return Array.from(this.element.audioTracks);
  }

  get audioLanguages(): string[] {
    return this.audioTracks.map((track) => track.language);
  }

  get activeAudioLanguage(): string | null {
    return this.audioTracks.find((track) => track.enabled)?.language || null;
  }

  set activeAudioLanguage(language: string | null) {
    this.audioTracks.forEach((track) => {
      track.kind = track.language === language ? 'main' : 'alternative';
      track.enabled = track.language === language;
    });
  }

  get activeRendition(): number | null {
    const index = this.element.videoRenditions.selectedIndex;
    return index == -1 ? null : index;
  }

  set activeRendition(index: number | null) {
    this.element.videoRenditions.selectedIndex = index == null ? -1 : index;
  }

  onFirstSubtitlesChange(listener: EventListener) {
    if (!this.element.textTracks) throw new Error('textTracks not available');

    this.element.textTracks.addEventListener('change', listener, { once: true });
    this.cleanup.push(() => {
      this.element.textTracks?.removeEventListener('change', listener);
    });
  }

  onSubtitlesChange(listener: EventListener) {
    if (!this.element.textTracks) throw new Error('textTracks not available');

    this.element.textTracks.addEventListener('change', listener);
    this.cleanup.push(() => {
      this.element.textTracks?.removeEventListener('change', listener);
    });
  }

  onAudioAdd(listener: EventListener) {
    this.element.audioTracks.addEventListener('addtrack', listener);
    this.cleanup.push(() => {
      this.element.audioTracks.removeEventListener('addtrack', listener);
    });
  }

  onSubtitlesAdd(listener: EventListener) {
    if (!this.element.textTracks) throw new Error('textTracks not available');

    this.element.textTracks.addEventListener('addtrack', listener);
    this.cleanup.push(() => {
      this.element.textTracks?.removeEventListener('addtrack', listener);
    });
  }

  onAudioChange(listener: EventListener) {
    this.element.audioTracks.addEventListener('change', listener);
    this.cleanup.push(() => {
      this.element.audioTracks.removeEventListener('change', listener);
    });
  }

  onRenditionChange(listener: EventListener) {
    this.element.videoRenditions.addEventListener('change', listener);
    this.cleanup.push(() => {
      this.element.videoRenditions.removeEventListener('change', listener);
    });
  }

  stopListening() {
    this.cleanup.forEach((fn) => fn());
  }
}
